import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LookupCategory } from "models";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LookupService, ValidationService } from "projects/common/src/lib";
import { PublicService } from "../public.service";

@Component({
    templateUrl: "./school-creation.component.html",
    styleUrls: ["school-creation.component.scss"],
})
export class SchoolCreationComponent {
    schoolForm: FormGroup;
    lookupCategory: any = LookupCategory;
    instituteData: any[] = [];
    educationBoardData: any[] = [];
    academicYearData: any[] = [];
    isFormSubmitted: boolean;
    isAddress = false;
    isPostalCode = false;

    constructor(
        private lookupService: LookupService,
        private formBuilder: FormBuilder,
        private publicService: PublicService,
        private spinner: NgxSpinnerService,
        private router: Router,
        private toastr: ToastrService) {
        this.createForm();
        this.getInstituteType();
        this.getEducationBoard();
        this.getAcademicYear();
    }

    ngOnInit() { }

    private getInstituteType() {
        this.lookupService.lookupDefaultSetUp(LookupCategory.INSTITUTE_TYPE).subscribe(result => {
            if (result) {
                this.instituteData = result;
            }
        })
    }

    private getEducationBoard() {
        this.lookupService.lookupDefaultSetUp(LookupCategory.EducationBoard).subscribe(result => {
            if (result) {
                this.educationBoardData = result;
            }
        })
    }

    private getAcademicYear() {
        this.lookupService.lookupDefaultSetUp(LookupCategory.ACADEMIC_YEAR).subscribe(result => {
            if (result) {
                this.academicYearData = result;
            }
        })
    }

    private createForm() {
        this.schoolForm = this.formBuilder.group({
            instituteName: ["", Validators.required],
            instituteType: ["", Validators.required],
            currentAcademicYear: ["", Validators.required],
            educationBoard: ["", Validators.required],
            email: ["", Validators.required],
            mobile: ["", [Validators.required, ValidationService.phoneNoValidator]],
            adminFirstName: ["", Validators.required],
            adminLastName: ["", Validators.required],
            adminEmail: ["", Validators.required],
            adminMobile: ["", [Validators.required, ValidationService.phoneNoValidator]],
            isCentralOfficer:["N", Validators.required],
            address: this.formBuilder.group({
                addressLine1: ["", Validators.required],
                city: [""],
                district: [""],
                state: [""],
                postalcode: ["", Validators.required],
            }),
        });
    }

    focusOnLabel(selector) {
        document.getElementById(selector).focus()
    }

    get address(): FormGroup {
        return this.schoolForm.get("address") as FormGroup;
    }

    save() {
        this.isFormSubmitted = true;

        if (this.schoolForm.controls['address'].value['addressLine1'] == null || this.schoolForm.controls['address'].value['addressLine1'] == '') {
            this.isAddress = true;
            //return false;
        } else {
            this.isAddress = false;
        }
        if (this.schoolForm.controls['address'].value['postalcode'] == null || this.schoolForm.controls['address'].value['postalcode'] == '') {
            this.isPostalCode = true;
            // return false;
        } else {
            this.isPostalCode = false;
        }


        if (this.schoolForm.controls['currentAcademicYear'].value == null || this.schoolForm.controls['currentAcademicYear'].value == '') {
            return false;
        }

        if (this.isAddress && this.isPostalCode) {
            return true;
        }

        if (this.schoolForm.invalid) {
            return;
        }
        this.spinner.show();
        const formData = this.schoolForm.value;
        this.spinner.hide();
        console.log('in save')
        this.publicService.addSchool(formData).subscribe((x: any) => {
            if(this.schoolForm.controls['isCentralOfficer'].value == 'N')
            {
             this.createDefaultSetup(x.schoolCd);
            }
            //this.spinner.hide();
            this.toastr.success('Request has been submitted successfully.','Success');
        }, err => {
            this.spinner.hide();
        });
    }

    createDefaultSetup(schoolCode) {
        this.spinner.show();
        this.publicService.createDefaultSetup(schoolCode).subscribe(x => {
            this.spinner.hide();
        }, err => {
            this.spinner.hide();
        });
    }

    cancel() {
        this.router.navigate(['/login']);
    }
}